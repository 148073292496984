<template>
  <div class="dispose-form">
    <van-form
      ref="dispose_up_form"
      class="dispose-up-form"
      input-align="right"
      error-message-align="right"
      validate-trigger="onSubmit"
      :readonly="mode === 'view'"
      :colon="true"
      :scroll-to-error="true"
      :show-error="false"
      :show-error-message="true"
      :submit-on-enter="false"
      @submit="submitOnHandle"
    >
      <div class="title-cell">
        <div class="title-text">处置信息填写</div>
      </div>
      <van-field
        class="work-describe"
        required
        v-model="form.disposeDisc"
        type="textarea"
        label="处置描述"
        placeholder="请输入处置描述"
        input-align="left"
        error-message-align="left"
        rows="2"
        autosize
        maxlength="200"
        show-word-limit
        :rules="[{ required: true, message: '处置描述不能为空' }]"
      />
      <van-field
        class="work-photo"
        required
        label="处置照片"
        input-align="left"
        error-message-align="left"
        :rules="[{ required: true, message: '处置照片不能为空' }]"
      >
        <template #input>
          <v-upload
            ref=""
            class=""
            :imgUrl.sync="form.photos"
            :activityPicture.sync="form.disposeImageUrl"
            :maxCount="10"
            :readonly="mode === 'view'"
            :show-upload="mode === 'edit'"
            :deletable="mode === 'edit'"
            uploadUrl="/cyproxy/sys/uploadFile"
          />
        </template>
      </van-field>
    </van-form>
  </div>
</template>

<script>
import { Button, Form } from "vant";
import _ from "lodash";
export default {
  name: "disposeForm",
  components: {
    [Form.name]: Form,
  },
  props: {
    mode: {
      type: String,
      default: "edit", // 可编辑: edit    仅查看: view
    },
    detail: {
      type: Object,
      default: null,
    },
    existForm: Function,
  },
  model: {},
  data() {
    return {
      textForm: {},
      form: {
        disposeDisc: "",
        disposeImageUrl: "",
        photos: "",
      },
    };
  },
  computed: {},
  watch: {
    detail: {
      handler(value) {
        if (value) {
          Object.keys(this.form).forEach((k) => {
            if (k === "photos") {
              this.form[k] = value.disposeImageUrl;
            } else {
              this.form[k] = value[k];
            }
          });
        }
      },
      immediate: true,
      deep: true,
    },
  },
  created() {},
  mounted() {
    const formRef = this.$refs.dispose_up_form;
    ["submit", "validate", "resetValidation", "scrollToField"].forEach(
      (key) => {
        this[key] = formRef[key];
      }
    );
  },
  beforeDestroy() {},
  methods: {
    submitOnHandle(values) {
      this.$emit(
        "submit",
        values,
        _.cloneDeep(this.form),
        _.cloneDeep(this.textForm)
      );
    },
  },
};
</script>

<style lang="less" scoped>
.dispose-form {
  margin-top: 20px;
}
.dispose-up-form {
  box-sizing: border-box;
  border-radius: 16px;
  overflow: hidden;
  background-color: #ffffff;
}
.title-cell {
  box-sizing: border-box;
  padding: 20px 16px 0px;
  .title-text {
    font-size: 26px;
    font-weight: 600;
    padding-bottom: 10px;
    color: rgba(0, 0, 0, 0.85);
    border-bottom: 1px solid #ebedf0;
    position: relative;
    padding-left: 12px;
    &::before {
      content: "";
      width: 4px;
      height: 26px;
      background-color: #3d7bfa;
      position: absolute;
      left: 0px;
      top: calc(50% - 5px);
      transform: translate(0, -50%);
    }
  }
}
.work-describe {
  flex-wrap: wrap;
  /deep/ .van-field__value {
    box-sizing: border-box;
    width: 100%;
    background-color: #f9f9f9;
    flex-shrink: 0;
    flex-basis: auto;
    padding: 8px 20px 8px 20px;
    border-radius: 16px;
  }
}
.work-photo {
  flex-wrap: wrap;
  /deep/ .van-field__value {
    box-sizing: border-box;
    width: 100%;
    flex-shrink: 0;
    flex-basis: auto;
  }
}
</style>
